import { ChangeEvent } from 'react';
import { useCompleteWith } from '@cs/hooks';
import { AdditionalPropertiesDto } from '@cs/state/model';
import { Checkbox } from '@facephi/ui-react';
import { WidgetProps } from '@rjsf/utils';
import { useTranslation } from 'react-i18next';
import { ErrorLabel } from '../StylesSwitch';

export const CheckboxCustom = ({
  value,
  onChange,
  label,
  disabled,
  rawErrors,
  schema,
  required,
  id,
}: WidgetProps) => {
  const { t } = useTranslation();

  useCompleteWith(
    schema.additionalProperties as AdditionalPropertiesDto,
    onChange,
  );

  const onChangeHandle = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.checked);
  }

  return (
    <>
      <Checkbox
        checked={value}
        id={id}
        onChange={onChangeHandle}      
        disabled={disabled}
        testId="test-input-json-schema"
      >{t(label) + `${required ? ' *' : ''}`}</Checkbox>
      {rawErrors?.[0] && <ErrorLabel>{rawErrors?.[0]}</ErrorLabel>}
    </>
  );
};
