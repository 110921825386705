import { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import {
  CardLeftUserIdElements,
  CardLeftUserIdHeader,
  ProfileCardContent,
  UserIdInputUpload,
} from '@cs/components';
import { useApi } from '@cs/hooks';
import { AcceptedFiles, MaxSizeImage } from '@cs/state/constants';
import { Client, editClientSchema } from '@cs/state/model';
import { updateClient } from '@cs/state/mutations';
import {
  Button,
  CardBase,
  CardBaseFooter,
  FlexContainer,
  Input,
  Label,
  Spinner,
  useToast,
  Switch,
  DropdownFlags,
} from '@facephi/ui-react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, FieldValues, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export const CardClientId = ({
  client,
  clientId,
}: {
  client: Client;
  clientId: string;
}) => {
  const { t } = useTranslation();
  const { toastManager } = useToast();
  const { uploadFile, getFile } = useApi();

  const [avatar, setAvatar] = useState<Blob>();
  const [image, setImage] = useState<File>();
  const [loading, setLoading] = useState<boolean>(false);

  const [editClient] = useMutation(updateClient);

  useEffect(() => {
    if (client.avatar) {
      getFile(client.avatar).then((data) => setAvatar(data));
    }
  }, [client]);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FieldValues>({
    resolver: yupResolver(editClientSchema),
    mode: 'onChange',
    defaultValues: client,
  });

  const handleImage = (img: File) => setImage(img);

  const onSubmit = async (values: FieldValues) => {
    setLoading(true);
    try {
      let fileId;
      if (image) {
        ({ id: fileId } = await uploadFile({
          clientId,
          file: image,
        }));
      }
      await editClient({
        variables: {
          id: client.id,
          client: {
            name: values.name,
            description: values.description,
            email: values.email || '',
            avatar: fileId,
            country: values.country,
            platform: values.platform
          },
        },
        onCompleted: () => {
          toastManager({
            type: 'success',
            message: t('Client modified successfully'),
            testId: 'toast-modify-client',
          });
        },
      });
    } catch {
      toastManager({
        type: 'error',
        message: t('Somenthing went wrong, try again'),
        testId: 'toast-modify-error',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <CardBase flexDirection="column" as="form" flex="2">
      {loading && <Spinner />}
      <CardLeftUserIdHeader>
        <UserIdInputUpload
          value={avatar}
          acceptFiles={AcceptedFiles}
          maxSize={MaxSizeImage}
          onChange={handleImage}
        />
      </CardLeftUserIdHeader>

      <ProfileCardContent flexDirection="column" rowGap="6">
        <FlexContainer as="section" rowGap="2" flexDirection="column">
          <Label fontSize="16" semibold>
            {t('General')}
          </Label>

          <CardLeftUserIdElements columnGap="7">
            <Input
              {...register('name')}
              testId="input-name"
              label={t('Name')}
              errorLabel={errors.name?.message && t(errors.name.message)}
            />
            <Input
              value={client.id}
              testId="input-userId"
              label={t('ID')}
              disabled
            />
          </CardLeftUserIdElements>
        </FlexContainer>

        <CardLeftUserIdElements columnGap="7">
          <Input
            {...register('description')}
            testId="input-description"
            label={t('Description')}
            errorLabel={
              errors.description?.message && t(errors.description.message)
            }
          />

          <Input
            {...register('email')}
            testId="input-email"
            label={t('Contact Email')}
            errorLabel={errors.email?.message && t(errors.email.message)}
          />
        </CardLeftUserIdElements>

        <CardLeftUserIdElements columnGap="7">
          <Controller
            name="platform"
            control={control}
            render={({ field }) => {
              return (
                <Switch
                  labelRight={t('With Platform')}
                  checked={field.value}
                  name={field.name}
                  onChange={field.onChange}
                />
              );
            }}
          />
          <Controller
            name="country"
            control={control}
            render={({ field }) => {
              return (
                <DropdownFlags
                  {...field}
                  label={t('Country')}
                  autoComplete="off"
                />
              );
            }}
          />
        </CardLeftUserIdElements>
      </ProfileCardContent>

      <CardBaseFooter as="section" hasPadding>
        <Button
          type="submit"
          onClick={handleSubmit(onSubmit)}
          testId="button-modify-user"
          size="L"
        >
          {t('Save')}
        </Button>
      </CardBaseFooter>
    </CardBase>
  );
};
